import { IEndorsementSlice } from './types';

export const initialState: IEndorsementSlice = {
    searchValue: '',
    pendingEndorsements: {
        data: null,
        error: null,
        loading: false
    },
    completedEndorsements: {
        data: null,
        error: null,
        loading: false
    },
    allExceptions: {
        data: [],
        error: null,
        loading: false
    },
    currentEndorsementExceptions: {
        data: [],
        error: null,
        loading: false
    }
};
