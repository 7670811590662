import { ICDAccountSlice } from './types';

export const initialState: ICDAccountSlice = {
    cdAccountList: {
        data: null,
        error: null,
        loading: false
    },
    searchedCDAccountList: {
        data: null,
        error: null,
        loading: false
    },
    crudCdAccount: {
        data: null,
        error: null,
        loading: false
    },
    cdAccount: {
        data: null,
        error: null,
        loading: false
    }
};
