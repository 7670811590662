import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../redux';
import {
    fetchCompletedEndorsementList,
    fetchPendingEndorsementList
} from '../../../../../../redux/slices/EndorsementSlice/thunk';
import { IEndorsementsSelector } from '../../../../../../redux/slices/EndorsementSlice/types';

export const usePendingEndorsements =
    (isCompletedEndorsements: boolean): { endorsements: IEndorsementsSelector, refetch: () => void } => {
        const dispatch = useDispatch();
        const endorsements = useSelector((state: ReduxState) => state.endorsementSlice.pendingEndorsements);
        useEffect(() => {
            dispatch(fetchPendingEndorsementList());
        }, [isCompletedEndorsements]);

        const refetch = () => {
            dispatch(fetchPendingEndorsementList());
        };

        return { endorsements, refetch };
    };
export const useCompletedEndorsements =
    (isCompletedEndorsements: boolean): { endorsements: IEndorsementsSelector, refetch: () => void } => {
        const dispatch = useDispatch();
        const endorsements = useSelector((state: ReduxState) => state.endorsementSlice.completedEndorsements);
        useEffect(() => {
            dispatch(fetchCompletedEndorsementList());
        }, [isCompletedEndorsements]);

        const refetch = () => {
            dispatch(fetchCompletedEndorsementList());
        };

        return { endorsements, refetch };
    };
