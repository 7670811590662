import { Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { Loader } from '../../atoms';
import { StyledEmptyStateContainer } from '../../pages/SPAContainer/pages/CDLedger/styles';
import { StyledTableLoaderContainer } from '../../pages/SPAContainer/pages/Endorsements/styles';
import { ICacheLoader } from './types';

const CacheLoader: React.FC<ICacheLoader> = ({
    isDataPresent,
    mode = 'Cache',
    errorMessage,
    showEmptyState = false,
    loading,
    children
}) => {
    const hasError = !!errorMessage;
    const shouldPresentCache = mode === 'Cache' && isDataPresent;
    const shouldPresentLoading =
    (hasError && loading) || (loading && !shouldPresentCache);
    const shouldShowError = hasError && !loading;
    const shouldShowEmptyState = showEmptyState && !isDataPresent;

    return shouldShowError ? (
        <Typography variant="medium" color="error">
            {errorMessage}
        </Typography>
    ) : shouldPresentLoading ? (
        <StyledTableLoaderContainer>
            <Loader data-testid="loading-spinner" />
        </StyledTableLoaderContainer>
    ) : shouldShowEmptyState ? (
        <StyledEmptyStateContainer>
            <Typography variant="medium"> No result found </Typography>
        </StyledEmptyStateContainer>
    ) : (
        <>{children}</>
    );
};

export default CacheLoader;
