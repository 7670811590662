import { Column } from 'exceljs';
import {
    IAllSheetHeaderKeys,
    IHrMappingHeaderInfoType,
    IHrMappingSheetNames
} from '../types';

export const HR_MAPPING_SHEET_HEADERS_LIST: Record<
  IHrMappingSheetNames,
  IAllSheetHeaderKeys[]
> = {
    'User Access': [
        'srNo',
        'companyId',
        'companyName',
        'email',
        'mobile',
        'hrdFlag',
        'selfServeFlag',
        'commsFlag',
        'errors'
    ],
    'Company Access': [
        'srNo',
        'companyId',
        'companyName',
        'tapOutDate',
        'csmUserId',
        'opsAgentUserId',
        'errors'
    ],
    'Grade <> SI Mapping': ['srNo', 'policyId', 'slabId', 'gradeId', 'errors']
};

export const HR_MAPPING_SHEET_HEADERS_DEF: Record<
  IAllSheetHeaderKeys,
  Partial<Column>
> = {
    srNo: {
        header: 'Sr No',
        key: 'srNo',
        width: 10
    },
    companyId: {
        header: 'Company ID',
        key: 'companyId',
        width: 30
    },
    companyName: {
        header: 'Company Name',
        key: 'companyName',
        width: 30
    },
    email: {
        header: 'Email',
        key: 'email',
        width: 30
    },
    mobile: {
        header: 'Mobile',
        key: 'mobile',
        width: 25
    },
    hrdFlag: {
        header: 'HRD Flag',
        key: 'hrdFlag',
        width: 15
    },
    selfServeFlag: {
        header: 'Self Serve Flag',
        key: 'selfServeFlag',
        width: 20
    },
    tapOutDate: {
        header: 'Tap Out Date',
        key: 'tapOutDate',
        width: 25
    },
    csmUserId: {
        header: 'CSM User ID',
        key: 'csmUserId',
        width: 30
    },
    opsAgentUserId: {
        header: 'Ops Agent User ID',
        key: 'opsAgentUserId',
        width: 30
    },
    commsFlag: {
        header: 'Comms Flag',
        key: 'commsFlag',
        width: 15
    },
    policyId: {
        header: 'Policy ID',
        key: 'policyId',
        width: 30
    },
    slabId: {
        header: 'Slab ID',
        key: 'slabId',
        width: 15
    },
    gradeId: {
        header: 'Grade ID',
        key: 'gradeId',
        width: 15
    },
    errors: {
        header: 'Errors',
        key: 'errors',
        width: 50
    }
};

export const HR_MAPPING_SHEET_HEADERS_EXTRA_INFO: Record<
  IAllSheetHeaderKeys,
  IHrMappingHeaderInfoType
> = {
    srNo: 'Optional',
    companyId: 'Mandatory',
    companyName: 'Optional',
    email: 'Conditional',
    mobile: 'Conditional',
    hrdFlag: 'Mandatory',
    selfServeFlag: 'Conditional',
    tapOutDate: 'Conditional',
    csmUserId: 'Conditional',
    opsAgentUserId: 'Conditional',
    commsFlag: 'Conditional',
    policyId: 'Mandatory',
    slabId: 'Mandatory',
    gradeId: 'Mandatory',
    errors: 'Informational'
};

export const HR_MAPPING_INFO_HEADERS_BG: Record<
  IHrMappingHeaderInfoType,
  string
> = {
    Mandatory: 'D9EAD3',
    Optional: 'FFF2CC',
    Conditional: 'D9EAD3',
    Informational: 'EDCDCC'
};
