import * as React from 'react';
import {
    createColumnHelper, flexRender, getCoreRowModel,
    getSortedRowModel, SortingState, useReactTable,
    getPaginationRowModel
} from '@tanstack/react-table';
import { Bold, Chip } from '../../../../atoms';
import styled from 'styled-components';
import { IEndorsements, IEndorsementsUIEntity, IStatus } from '../../../../../redux/slices/EndorsementSlice/types';
import { ChevronDownIcon, ChevronRightIcon } from '../../../../../assets/img';
import SVG from 'react-inlinesvg';
import {
    ENDORSEMENT_STATUS_CHIP_COLOR,
    ENDORSEMENT_STATUS_TEXT_COLOR, getStatusLabel
} from '../../../../../utils/constants/Endorsement/PossibleValue';
import { transformEndorsementListForUI } from '../../../../../utils/constants/Endorsement/transforms';
import { isEndoToolingEnabled } from '../../../../../utils/featureFlags';
import { LoopButton } from '@loophealth/loop-ui-web-library';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { textPlaceholder } from '../../../../containers/ValidationSummaryTable/utils';

export const StyledTable = styled.table`
  background-color: #fff;
  width: 100%;
  margin: 28px auto;
  table-layout: fixed;
  min-width: 501px;
  border-collapse: collapse;
`;
export const StyledCol = styled.col`
  width: 190px;
`;
export const StyledWCol = styled.col`
  width: 160px;
`;
export const StyledNCol = styled.col`
  width: 100px;
`;
export const StyledTh = styled.th`
  border-collapse: collapse;
  padding: 16px;
  text-align: left;
  font-weight: 600;
  background-color: ${(p) => p.theme.colors.primary};
  font-size: ${(p) => p.theme.fontSizes.subHeading};
  color: ${(p) => p.theme.colors.white};
`;
export const StyledTrHead = styled.tr`
  border-bottom: 1px solid rgba(89, 89, 89, 0.2);
`;
export const StyledTrBody = styled.tr`
  border-bottom: 1px solid rgba(89, 89, 89, 0.2);
  background-color: #FCFCFC;

  :hover {
    cursor: pointer;
    background: rgba(2, 95, 76, 0.05);
    box-shadow: 0 7px 10px rgba(42, 78, 70, 0.15);
    border-bottom: 1px solid rgba(42, 78, 70, 0.15);
  }
`;
export const StyledTd = styled.td`
  border-collapse: collapse;
  padding: 16px;
  text-align: left;
  color: ${(p) => p.theme.colors.beyondGrey};

  div {
    width: 100%;
    min-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const StyledColHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledHeaderIcon = styled(SVG)`
  cursor: pointer;
  margin-left: 8px;

  & path {
    stroke: ${(p) => p.theme.colors.white};
  }
`;
export const StyledLoading = styled.div`
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: inherit;
  height: 50%;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  gap: 16px;
  background-color: #FCFCFC;
`;

export const StyledPaginationButton = styled.div`
  display: flex;
  height: 40px;
  width: 80px;
`;

export const PageInfo = styled.span`
  color: ${(p) => p.theme.colors.beyondGrey};
  font-size: ${(p) => p.theme.fontSizes.body};
`;

export const StyledFitContent = styled.span`
    width: fit-content;
    `;
const columnHelper = createColumnHelper<IEndorsementsUIEntity>();
let columns = [
    columnHelper.accessor('companyName', {
        header: 'Company Name',
        cell: (info) => {
            const companyName = info.getValue();
            return <Bold>{companyName ?? '-'}</Bold>;
        },
        enableSorting: false
    }),
    columnHelper.accessor('insurerName', {
        header: 'Insurer Name',
        enableSorting: false
    }),
    columnHelper.accessor('policyType', {
        header: 'Policy Type',
        enableSorting: false
    }),
    columnHelper.accessor('nickName', {
        header: 'Nickname',
        enableSorting: false
    }),
    columnHelper.accessor(
        isEndoToolingEnabled ? 'endorsementCycle' : 'endorsementNumber',
        {
            header: 'Endo Cycle',
            enableSorting: false
        }
    ),
    columnHelper.accessor('livesCount', {
        header: 'No. of lives',
        enableSorting: false
    }),
    columnHelper.accessor('status', {
        header: 'Endo Status',
        enableSorting: false,
        cell: (info) => {
            const status = info.getValue() as IStatus;
            return <Chip
                color={ENDORSEMENT_STATUS_TEXT_COLOR[status]}
                borderRadius={8}
                label={getStatusLabel(status) as string}
                backgroundColor={ENDORSEMENT_STATUS_CHIP_COLOR[status]}
            />;
        }
    }),
    columnHelper.accessor('createdAt', {
        header: () => <StyledColHead>Date of Creation</StyledColHead>,
        enableSorting: false
    }),
    columnHelper.accessor('policyNumber', {
        header: () => <StyledColHead>Policy No.</StyledColHead>,
        enableSorting: false,
        cell: (info) => {
            const id = info.row.original.policyId;
            const navigate = useNavigate();
            return id ? <StyledFitContent>
                <LoopButton variant='text' onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.POLICY_DETAILS.route + '?id=' + id);
                }}>
                    {textPlaceholder(id)}
                </LoopButton>
            </StyledFitContent> : '--';
        }
    }),
    columnHelper.accessor('supportTicketId', {
        header: () => <StyledColHead>FD Ticket</StyledColHead>,
        enableSorting: false,
        cell: (info) => {
            const id = info.getValue();
            return id ? <StyledFitContent>
                <LoopButton variant='text' onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.open(`https://loophealth.freshdesk.com/a/tickets/${id}`, '_blank');
                }}>
                    {textPlaceholder(id)}
                </LoopButton>
            </StyledFitContent> : '--';
        }
    })
];

interface CompanyTableProps {
    data: IEndorsements[];
    onRowClick(endorsementId: string): void;
    pageSize?: number;
}

const CompanyDataTable: React.FunctionComponent<CompanyTableProps> = ({
    data,
    onRowClick,
    pageSize = 10
}) => {
    const [sorting, setSorting] = React.useState<SortingState>([]);

    const transformedData = React.useMemo(
        () => transformEndorsementListForUI(data),
        [data]
    );

    if (!isEndoToolingEnabled) {
        columns = columns.filter((column) => column.header !== 'Nickname');
    }

    const table = useReactTable({
        data: transformedData,
        columns,
        state: {
            sorting
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize,
                pageIndex: 0
            }
        }
    });

    const {
        getCanPreviousPage,
        getCanNextPage,
        nextPage,
        previousPage,
        getPageCount,
        getState
    } = table;

    const { pagination } = getState();

    const rows = table.getRowModel().rows ?? [];

    return (
        <>
            <StyledTable>
                <colgroup>
                    <StyledCol />
                    <StyledCol />
                    <StyledNCol />
                    <StyledWCol />
                    {isEndoToolingEnabled && <StyledNCol />}
                    <StyledNCol />
                    <StyledWCol />
                    <StyledNCol />
                    <StyledNCol />
                    <StyledNCol />
                </colgroup>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <StyledTrHead key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <StyledTh key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : <div
                                            {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : '',
                                                onClick: header.column.getToggleSortingHandler()
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: <StyledHeaderIcon width={24} src={ChevronRightIcon} />,
                                                desc: <StyledHeaderIcon width={24} src={ChevronDownIcon} />
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    }
                                </StyledTh>
                            ))}
                        </StyledTrHead>
                    ))}
                </thead>
                {rows.length === 0 ? (
                    <StyledLoading>No Results! Try changing filters</StyledLoading>
                ) : (<tbody>{
                    rows.map((row) => (
                        <StyledTrBody key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <StyledTd
                                    key={cell.id}
                                    onClick={() => onRowClick(cell.row?.original?.id)}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </StyledTd>
                            ))}
                        </StyledTrBody>
                    ))
                }</tbody>)}
            </StyledTable>

            {rows.length > 0 && (
                <PaginationContainer>
                    <PageInfo>
                        Page {pagination.pageIndex + 1} of{' '}
                        {getPageCount()}{' '}
                        ({transformedData.length} total records)
                    </PageInfo>
                    <StyledPaginationButton>
                        <LoopButton
                            size='medium'
                            onClick={() => previousPage()}
                            variant={!getCanPreviousPage() ? 'disabled' : 'filled'}
                        >
                            Previous
                        </LoopButton>
                    </StyledPaginationButton>
                    <StyledPaginationButton>
                        <LoopButton
                            size='medium'
                            onClick={() => nextPage()}
                            variant={!getCanNextPage() ? 'disabled' : 'filled'}
                        >
                            Next
                        </LoopButton>
                    </StyledPaginationButton>
                </PaginationContainer>
            )}
        </>
    );
};

export default CompanyDataTable;
