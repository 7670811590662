import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    createCdAccount, fetchCDAccounts, fetchCDAccountsByField,
    updateCdAccount, fetchCDAccountById
} from '../../../adapters';
import { ICDAccount } from './types';

const CD_ACCOUNTSLICE_ACTION_TYPES = {
    FETCH_CD_ACCOUNTS: 'FETCH_CD_ACCOUNTS',
    SEARCH_CD_ACCOUNTS: 'SEARCH_CD_ACCOUNTS',
    CREATE_CD_ACCOUNT: 'CREATE_CD_ACCOUNT',
    UPDATE_CD_ACCOUNT: 'UPDATE_CD_ACCOUNT',
    FETCH_CD_ACCOUNT_BY_ID: 'FETCH_CD_ACCOUNT_BY_ID'
};

export const fetchCDAccountList = createAsyncThunk(CD_ACCOUNTSLICE_ACTION_TYPES.FETCH_CD_ACCOUNTS, async () => {
    try {
        const response = await fetchCDAccounts();
        const cdAccounts = response.data as ICDAccount[];
        return {
            cdAccountList: {
                data: cdAccounts
            }
        };
    } catch (e) {
        throw e;
    }
});

export const fetchCDAccount = createAsyncThunk(CD_ACCOUNTSLICE_ACTION_TYPES.FETCH_CD_ACCOUNT_BY_ID,
    async (cdAccountId: string) => {
        try {
            const response = await fetchCDAccountById(cdAccountId);
            const cdAccount = response.data as ICDAccount[];
            return cdAccount[0];
        } catch (e) {
            throw e;
        }
    });

export const fetchCDAccountListByField = createAsyncThunk(
    CD_ACCOUNTSLICE_ACTION_TYPES.SEARCH_CD_ACCOUNTS,
    async ({ searchField, searchFieldValue }: { searchField: string; searchFieldValue: string }) => {
        try {
            const response = await fetchCDAccountsByField(searchField, searchFieldValue);
            const cdAccounts = response.data as ICDAccount[];
            return {
                cdAccountList: {
                    data: cdAccounts
                }
            };
        } catch (e) {
            throw e;
        }
    }
);

export const createCDAccount = createAsyncThunk(
    CD_ACCOUNTSLICE_ACTION_TYPES.CREATE_CD_ACCOUNT,
    async (
        { cdAccount, handleFormNavigation }: { cdAccount: Partial<ICDAccount>; handleFormNavigation: () => void },
        { dispatch }
    ) => {
        try {
            await createCdAccount(cdAccount);
            dispatch(fetchCDAccountList());
            handleFormNavigation();
        } catch (e) {
            throw e;
        }
    }
);

export const updateCDAccount = createAsyncThunk(
    CD_ACCOUNTSLICE_ACTION_TYPES.UPDATE_CD_ACCOUNT,
    async (
        { cdAccount, handleFormNavigation }: { cdAccount: Partial<ICDAccount>; handleFormNavigation: () => void },
        { dispatch }
    ) => {
        try {
            await updateCdAccount(cdAccount);
            dispatch(fetchCDAccountList());
            handleFormNavigation();
        } catch (e) {
            throw e;
        }
    }
);
