import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableRow, TableHeading, Loader, UploadModal } from '../../../../atoms';
import { CDLedgerRow } from '../../../../containers/CDLedgerRow';
import { StyledTitleIcon } from '../ChangeRequestsOfOneCompany/styles';
import {
    StyledCard,
    StyledCardChild,
    StyledHeader,
    StyledContainer,
    StyledHeaderActionContainer,
    FieldRowCard,
    AccountDetailsRowCard,
    Spacer,
    StyledEmptyStateContainer,
    StyledUploadCDButton,
    StyledEditSVG,
    StyledPolicyFilterContainer
} from './styles';
import { ArrowLeftIcon, EditIcon, MailIcon } from '../../../../../assets/img';
import { fetchCDLedgerEntriesByID } from '../../../../../adapters';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ICDLedger } from '../../../../../redux/slices/CDAccountSlice/types';
import { StyledTableLoaderContainer } from '../CDAccount/styles';
import AddCdBalanceModal from '../../../../containers/AddCdBalanceModal';
import {
    deleteCDStatementFile,
    downloadCDStatementFile,
    fetchStateData,
    getPolicyFilters,
    handleDownload,
    navigateBack,
    uploadCDStatementFile
} from './utils';
import { fetchCDAccount, fetchCDAccountList } from '../../../../../redux/slices/CDAccountSlice/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '../../../../hooks/useToast';
import { ReduxState } from '../../../../../redux';
import {
    CurrencyTypography,
    DateFormatUtils,
    Form,
    LoopButton,
    TableEmptyState,
    Typography
} from '@loophealth/loop-ui-web-library';
import {
    isCdAccountDeletionEnabled,
    isCdLedgerDownloadEnabled,
    isEndoToolingEnabled
} from '../../../../../utils/featureFlags';
import { EditCdAccountIdModal } from '../../../../containers/EditCdAccountIdModal';
import DeleteCdAccountMenu from '../../../../containers/DeleteCdAccountMenu';

const CDLedger: React.FunctionComponent = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const user = useSelector((state: ReduxState) => state.user?.userData?.data);
    const ledgerId = searchParams.get('id') as string;
    const cdAccount = useSelector((state: ReduxState) => state.cdAccountSlice?.cdAccount?.data);
    const { company, ledgerInitialState } = fetchStateData(ledgerId);
    const [isCDBalanceEntryModalVisible, setIsCDBalanceEntryModalVisible] = React.useState(false);
    const [isEditCdAccountIdModalVisible, setIsEditCdAccountIdModalVisible] = React.useState(false);
    const [isCDUploadModalVisible, setIsCDUploadModalVisible] = React.useState(false);
    const [filesSelected, setFilesSelected] = React.useState<Record<string, unknown>[]>([]);

    const [transactionType, setTransactionType] =
        React.useState<'adjustment' | 'payment' | 'policyBookingAmount' | ''>('');
    const [downloading, setIsDownloading] = React.useState(false);
    const navigateToCdAccountsList = () => navigateBack(navigate);

    let tableHeaders = [
        'Entry Date',
        'Ledger ID',
        'Policy Number',
        'Policy Type',
        'Trans. Type',
        'Endo Number',
        'Endo Type',
        'Trans. Amount',
        'Source',
        'Added By',
        'Notes'
    ];

    if (!isEndoToolingEnabled) {
        tableHeaders = tableHeaders.filter((item) => item !== 'Added By');
    }
    const [ledgerEntries, setLedgerEntries] =
        React.useState<{ data: ICDLedger[]; loading: boolean }>(ledgerInitialState);

    const [selectedPolicyTypes, setSelectedPolicyTypes] = useState<string[]>([]);
    const policyFilters = useMemo(() => {
        const filters = getPolicyFilters(cdAccount?.policyData, ledgerEntries.data);
        setSelectedPolicyTypes(filters.map((item) => item.value));
        return filters;
    }, [cdAccount, ledgerEntries]);

    const filteredLedgerEntries = useMemo(
        () => ledgerEntries.data.filter(
            (entry) => selectedPolicyTypes.includes(entry.policyType)),
        [ledgerEntries.loading, selectedPolicyTypes]);

    React.useEffect(() => {
        const fetchCdLedgerEntries = async () => {
            try {
                const ledgerEntiresList = ((await fetchCDLedgerEntriesByID(ledgerId)).data as ICDLedger[]).map(
                    (entry) => {
                        return {
                            ...entry,
                            endorsementNumber: entry.endorsementNumber ? `Endo ${entry.endorsementNumber}` : '-',
                            createdAt: new DateFormatUtils().formatDateString(entry.createdAt)
                        };
                    }
                );
                setLedgerEntries({ data: ledgerEntiresList as ICDLedger[], loading: false });
            } catch (e) {
                // TODO: Handle error state
            }
        };
        fetchCdLedgerEntries();
    }, [isCDBalanceEntryModalVisible]);

    useEffect(() => {
        if (ledgerId) {
            dispatch(fetchCDAccount(ledgerId));
        }
    }, [ledgerId]);
    const handleEditCdAccountId = () => {
        setIsEditCdAccountIdModalVisible(true);
    };
    const handleAddAdjustment = () => {
        setTransactionType('adjustment');
        setIsCDBalanceEntryModalVisible(true);
    };
    const handleAddPolicyBookingAmount = () => {
        setTransactionType('policyBookingAmount');
        setIsCDBalanceEntryModalVisible(true);
    };
    const addDeposit = () => {
        setTransactionType('payment');
        setIsCDBalanceEntryModalVisible(true);
    };
    const onDownload = async () => {
        await handleDownload(ledgerId, user.email, toast, setIsDownloading);
    };

    const closeCDUploadModal = () => {
        setIsCDUploadModalVisible(false);
    };

    const openCDUploadModal = () => {
        setIsCDUploadModalVisible(true);
    };

    const handleDeleteCDStatement = async () => {
        if (!cdAccount?.cdDocument) return;
        await deleteCDStatementFile(ledgerId, cdAccount?.cdDocument);
        await dispatch(fetchCDAccountList());
    };

    const handleUploadCDStatement = async () => {
        if (!filesSelected.length) return;
        const file = filesSelected[0] as unknown as File;
        await uploadCDStatementFile(ledgerId, file.name as string, file);
        await dispatch(fetchCDAccountList());
        setFilesSelected([]);
    };

    const handleDownloadCDStatement = async () => {
        if (!cdAccount) return;
        await downloadCDStatementFile(ledgerId, cdAccount.cdDocument);
    };

    return (
        (cdAccount && (
            <StyledContainer>
                <UploadModal
                    title={cdAccount.cdDocument ? 'View CD Statement' : 'Upload CD Statement'}
                    subtitle={
                        <Typography variant='small' color='secondary'>
                            HRs will be able to access this on the HR Dashboard
                        </Typography>
                    }
                    uploadedFiles={cdAccount.cdDocument ? [cdAccount.cdDocument] : []}
                    formats={['.xls', '.xlsx']}
                    isVisible={isCDUploadModalVisible}
                    onModalClose={closeCDUploadModal}
                    filesSelected={filesSelected}
                    setFilesSelected={setFilesSelected}
                    onDelete={handleDeleteCDStatement}
                    onSave={handleUploadCDStatement}
                    onDownload={handleDownloadCDStatement}
                />
                <StyledHeader>
                    <StyledTitleIcon src={ArrowLeftIcon} alt={'Back Icon'} onClick={navigateToCdAccountsList} />
                    <Typography variant="title2" weight="medium">
                        {company?.name}
                    </Typography>
                    <StyledUploadCDButton>
                        <LoopButton
                            onClick={openCDUploadModal}
                            variant='filled'
                            size="medium"
                        >
                            {cdAccount.cdDocument ? 'View CD Statement' : 'Upload CD Statement'}
                        </LoopButton>
                        {isCdAccountDeletionEnabled && <DeleteCdAccountMenu cdAccount={cdAccount}/>}
                    </StyledUploadCDButton>
                </StyledHeader>
                <StyledCard>
                    <AccountDetailsRowCard>
                        <StyledCardChild>
                            <Typography variant="medium">
                                Account ID:
                            </Typography>
                            <FieldRowCard>
                                <Typography variant="title3">
                                    {cdAccount.accountNumber}
                                </Typography>
                                {isEndoToolingEnabled && (
                                    <StyledEditSVG src={EditIcon} onClick={handleEditCdAccountId} />
                                )}
                            </FieldRowCard>
                        </StyledCardChild>
                        <Spacer height={20} />
                        <StyledCardChild>
                            <Typography variant="medium">
                                Current CD Balance:
                            </Typography>
                            <CurrencyTypography
                                currency={'inr'}
                                value={cdAccount.balance}
                                variant="title3"
                            />
                        </StyledCardChild>
                    </AccountDetailsRowCard>

                    <StyledHeaderActionContainer>
                        <LoopButton variant="secondary" onClick={handleAddAdjustment}>
                            <Typography variant="small" weight="medium" color="emerald">
                                Add adjustment
                            </Typography>
                        </LoopButton>
                        <LoopButton variant="secondary" onClick={addDeposit}>
                            <Typography variant="small" weight="medium" color="emerald">
                                Add deposit
                            </Typography>
                        </LoopButton>
                        <LoopButton variant="secondary" onClick={handleAddPolicyBookingAmount}>
                            <Typography variant="small" weight="medium" color="emerald">
                                Add policy booking amount
                            </Typography>
                        </LoopButton>
                        {isCdLedgerDownloadEnabled && (
                            <LoopButton
                                onClick={onDownload}
                                variant={'text'}
                                iconSrc={MailIcon}
                                isLoading={downloading}
                            >
                                Send Email
                            </LoopButton>
                        )}
                    </StyledHeaderActionContainer>
                </StyledCard>
                {policyFilters.length > 1 && (
                    <StyledPolicyFilterContainer>
                        <Typography variant='small' color='secondary'>Policy Type:&nbsp;</Typography>
                        <Form.MultiSelectDropdown
                            listItems={policyFilters}
                            selectedItems={selectedPolicyTypes}
                            placeholder='Select policy type'
                            handleChange={setSelectedPolicyTypes}/>
                    </StyledPolicyFilterContainer>)
                }
                {(!ledgerEntries.loading && (
                    <Table>
                        <TableRow>
                            {tableHeaders.map((header, index) => (
                                <TableHeading key={index}>
                                    <b>{header}</b>
                                </TableHeading>
                            ))}
                        </TableRow>
                        {filteredLedgerEntries?.map((cdLedgerEntry, index) => (
                            <CDLedgerRow cdLedgerEntry={cdLedgerEntry} key={index} />
                        ))}
                        {(ledgerEntries.data && ledgerEntries.data.length === 0 && (
                            <StyledEmptyStateContainer>
                                <TableEmptyState message="Nothing to show here yet" />
                            </StyledEmptyStateContainer>
                        )) || <></>}
                    </Table>
                )) || (
                    <StyledTableLoaderContainer>
                        <Loader />
                    </StyledTableLoaderContainer>
                )}

                <AddCdBalanceModal
                    setIsVisible={setIsCDBalanceEntryModalVisible}
                    isVisible={isCDBalanceEntryModalVisible}
                    id={ledgerId}
                    type={transactionType}
                />
                <EditCdAccountIdModal
                    setIsVisible={setIsEditCdAccountIdModalVisible}
                    isVisible={isEditCdAccountIdModalVisible}
                    id={ledgerId}
                />
            </StyledContainer>
        )) || <Loader />
    );
};
export default CDLedger;
