import { RootState } from '../../reducers';
import { IEndorsementExceptionData } from './types';

export const selectSearchValue = (state: RootState): string => state.endorsementSlice.searchValue;
export const selectAllEndorsementExceptions = (
    state: RootState): IEndorsementExceptionData[] => state.endorsementSlice.allExceptions.data;
export const selectCurrentEndorsementExceptions = (
    state: RootState): IEndorsementExceptionData[] => state.endorsementSlice.currentEndorsementExceptions.data;
export const selectCurrentEndorsementExceptionsLoading = (
    state: RootState): boolean => state.endorsementSlice.currentEndorsementExceptions.loading;
