import React from 'react';
import ExternalRouter from './components/pages/router';
import ConfirmationModalContextProvider from './components/hooks/modalConfirmationContext';
import { LogoutAfterInactivity } from '@loophealth/loop-ui-web-library';
import { FirebaseAuth } from './components/pages/LoginContainer/adapters/provider';
import { useDispatch } from 'react-redux';
import { logoutUser } from './components/pages/LoginContainer/redux/slices/UserSlice';
import { cleanupAction } from './redux/store';
const App: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const handleLogoutOnInactivity = () => {
        if (FirebaseAuth.currentUser) {
            dispatch(logoutUser());
            dispatch(cleanupAction());
            window.location.pathname = '/login';
            FirebaseAuth.signOut();
        }
    };

    return (
        <ConfirmationModalContextProvider>
            <div className="App">
                <LogoutAfterInactivity logoutAction={handleLogoutOnInactivity}>
                    <ExternalRouter />
                </LogoutAfterInactivity>
            </div>
        </ConfirmationModalContextProvider>
    );
};

export default App;
