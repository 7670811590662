import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { IEndorsements, IEndorsementsSelector } from '../../../../../redux/slices/EndorsementSlice/types';
import { getStatusLabel } from '../../../../../utils/constants/Endorsement/PossibleValue';

export const getFilteredEndorsementList = (endorsements: IEndorsementsSelector, search: string): IEndorsements[] => {
    if (!search) return endorsements.data || [];
    const searchArray = search.split(',').map((term) => term.trim().toLowerCase()).filter(Boolean);
    return endorsements.data?.filter((c: IEndorsements) =>
        searchArray.every((searchTerm) =>
            [
                c?.companyName, c?.nickName, c?.insurerName, c?.policyType,
                `Endo ${c?.endorsementNumber}`, c?.livesCount?.toString(),
                getStatusLabel(c?.status), new DateFormatUtils().formatDateString(c?.createdAt),
                c?.policyID, c?.ticketId
            ].some((field) => field?.toLowerCase().includes(searchTerm))
        )
    ) || [];
};
