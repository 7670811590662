import { configureStore, createAction } from '@reduxjs/toolkit';
import {
    createMigrate,
    MigrationManifest,
    PersistConfig,
    PersistedState,
    persistReducer,
    persistStore
} from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import rootReducer, { RootState } from '../reducers';

// add version migration if breaking changes are made in redux
const migrations: MigrationManifest = {
    1: () => {
        return {} as PersistedState;
    }
};

const persistConfig: PersistConfig<RootState> = {
    key: 'root',
    version: Number(process.env.REACT_APP_VERSION ?? 1),
    storage: storage('genome'),
    blacklist: [
        'validatorSlice',
        'changeRequestSlice',
        'userPoliciesSlice',
        'policySlice',
        'applicationState',
        'user'
    ],
    migrate: createMigrate(migrations, { debug: false })
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});
const persistor = persistStore(store);
const cleanupAction = createAction('common/cleanup');

export default store;
export { persistor, cleanupAction };
export type AppDispatch = typeof store.dispatch;
