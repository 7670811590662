import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    StyledContainer, StyledHeader, StyledTitle,
    StyledLink, StyledIconContainer, StyledIcon,
    StyledSearchWrapper, StyledInput,
    StyledDownloadContainer, StyledDownloadImage, StyledTableLoaderContainer,
    StyledRefreshContainer, StyledRefreshIcon,
    StyledLeftWrapper
} from './styles';
import { ChevronRightIcon, DownloadIcon, RefreshIcon } from '../../../../../assets/img';
import CompanyDataTable from './CompanyDataTable';
import { excelDownload } from '../../../../../utils/common';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { useCompletedEndorsements, usePendingEndorsements } from './hooks/useEndorsements';
import { Loader } from '../../../../atoms';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchValue } from '../../../../../redux/slices/EndorsementSlice';
import { selectSearchValue } from '../../../../../redux/slices/EndorsementSlice/selectors';
import { getFilteredEndorsementList } from './utils';
import { Tooltip } from '@loophealth/loop-ui-web-library';
import { useToast } from '../../../../hooks/useToast';
import WithThemeProvider from '../../../../../theme/WithThemeProvider';

const Endorsements: React.FunctionComponent = () => {
    const [isCompletedEndorsements, toggleCompletedEndorsements] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const search = useSelector(selectSearchValue);
    const { endorsements, refetch } = isCompletedEndorsements
        ? useCompletedEndorsements(isCompletedEndorsements) : usePendingEndorsements(isCompletedEndorsements);
    const toast = useToast();

    const filteredEndorsementList = useMemo(() => {
        return getFilteredEndorsementList(endorsements, search);
    }, [search, endorsements.data, isCompletedEndorsements]);


    const handleRefresh = () => {
        refetch();
        toast?.displayToast('success', 'Endorsement data is being refreshed', '', 10000);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchValue(e.target.value));
    };

    const onViewCompletedEndorsements = () => {
        toggleCompletedEndorsements((isCompletedEndorsements) => !isCompletedEndorsements);
    };
    const downloadEndorsementData = () => {
        excelDownload(filteredEndorsementList, 'endorsement-data');
    };
    const onEndorsementClicked = (endorsementId: string) => {
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.ENDORSEMENT_DETAILS.route +
            '?id=' + endorsementId);
    };

    return (
        <StyledContainer>
            <StyledHeader>
                <StyledTitle>
                    Endorsement requests {filteredEndorsementList && `(${filteredEndorsementList.length})`}
                </StyledTitle>
                <StyledIconContainer onClick={onViewCompletedEndorsements}>
                    <StyledLink data-testid="view-completed-endorsement">
                        View {isCompletedEndorsements ? `Pending` : `Completed`} Endorsements
                    </StyledLink>
                    <StyledIcon src={ChevronRightIcon} alt={'Chevron Icon'} />
                </StyledIconContainer>
            </StyledHeader>
            <StyledSearchWrapper>
                <StyledLeftWrapper>
                    <StyledInput
                        name='name'
                        placeholder='Search by any field. Use comma to search multiple fields.'
                        value={search}
                        onChange={handleSearchChange}
                    />
                    <Tooltip
                        type="informative"
                        beak="bottom-center"
                        text="Refresh Endorsements Data"
                        delay={500}
                    >
                        <StyledRefreshContainer onClick={handleRefresh}>
                            <StyledRefreshIcon src={RefreshIcon} />
                        </StyledRefreshContainer>
                    </Tooltip>
                </StyledLeftWrapper>
                <Tooltip
                    type="informative"
                    beak="bottom-center"
                    text="Download All Endorsements Data"
                    delay={500}
                >
                    <WithThemeProvider>
                        <StyledDownloadContainer onClick={downloadEndorsementData}>
                            <StyledDownloadImage src={DownloadIcon} />
                            Download as excel
                        </StyledDownloadContainer>
                    </WithThemeProvider>
                </Tooltip>
            </StyledSearchWrapper>
            {endorsements.loading && !endorsements.data?.length ? (
                <StyledTableLoaderContainer><Loader /></StyledTableLoaderContainer>
            ) : null}
            {endorsements.data?.length ? (
                <CompanyDataTable data={filteredEndorsementList} onRowClick={onEndorsementClicked} />
            ) : null}
        </StyledContainer>
    );
};
export default Endorsements;
